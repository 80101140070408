var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.dataheaders,"items":_vm.data,"search":_vm.search,"loading":_vm.loadingData,"sort-by":"co_cli_po","footer-props":{itemsPerPageText: 'Mostrar'}},scopedSlots:_vm._u([{key:"item.nombre_comercial",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-start py-3"},[_c('v-icon',{attrs:{"color":"primary","left":"","size":"20"}},[_vm._v(" mdi-account-tie ")]),_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('span',{staticClass:"primary--text font-weight-bold",domProps:{"textContent":_vm._s(item.nombre_comercial)}}),_c('div',{staticClass:"subtitle blue-grey--text font-italic"},[_vm._v("RIF: "+_vm._s(item.rif))])])],1)]}},{key:"item.persona_contacto",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('span',{staticClass:"primary--text font-weight-bold",domProps:{"textContent":_vm._s(item.persona_contacto)}}),_c('div',{staticClass:"subtitle blue-grey--text font-italic"},[_vm._v("Teléfono: "+_vm._s(item.telefono))])])]}},{key:"item.ven_des",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"black--text font-weight-regular"},[_c('strong',[_vm._v("("+_vm._s(item.co_ven)+")")]),_vm._v(" "+_vm._s(item.ven_des)+" ")])])]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-chip',{staticClass:"pa-1",attrs:{"label":"","color":"blue-grey lighten-5"}},[_c('v-icon',{staticClass:"mx-2",attrs:{"size":"20","color":item.lat !== null && item.lng !== null ? 'icono' : 'blue-grey lighten-3'},domProps:{"textContent":_vm._s(item.lat !== null && item.lng !== null ? 'mdi-map-marker-check' : 'mdi-map-marker-off' )}}),_vm._t("actionInfo",null,{"client":item})],2)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right d-flex justify-end"},[(_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticClass:"d-flex justify-end"},[_c('v-chip',{staticClass:"pa-1",attrs:{"label":"","color":"blue-grey lighten-5"}},[_c('v-icon',{staticClass:"mx-2",attrs:{"size":"20","color":item.lat !== null && item.lng !== null ? 'icono' : 'blue-grey lighten-3'},domProps:{"textContent":_vm._s(item.lat !== null && item.lng !== null ? 'mdi-map-marker-check' : 'mdi-map-marker-off' )}})],1)],1):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"plain":"","small":"","color":"blue-grey"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"icono"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0",attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.$hasPermission('clientes.consulta'))?[_c('v-list-item',{staticClass:"py-2",attrs:{"to":{name: 'ClientePotencial', params: { cod: item.co_cli_po }}}},[_c('v-list-item-icon',{staticClass:"my-2 ml-1 mr-3 align-self-center"},[_c('v-icon',{attrs:{"color":"light-blue darken-3","size":"22"}},[_vm._v(" mdi-text-box-check-outline ")])],1),_c('v-list-item-title',[_c('span',[_vm._v("Ver detalles")])])],1),_c('v-divider')]:_vm._e(),(_vm.$hasPermission('clientes.potencial.editar'))?_c('v-list-item',{staticClass:"py-2",on:{"click":function($event){return _vm.gestionItem(item)}}},[_c('v-list-item-icon',{staticClass:"my-2 ml-1 mr-3 align-self-center"},[_c('v-icon',{attrs:{"size":"22","color":"light-blue darken-3"}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v("Editar")])],1):_vm._e()],2)],1)],1)],1)]}},{key:"loading",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"table-tbody","width":_vm.$vuetify.breakpoint.xsOnly ? _vm.$vuetify.breakpoint.width - 70 : '100%'}},'v-skeleton-loader',_vm.attrs,false))],1)],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }