<template>
<div>
  <v-row class="pa-0 ma-0">
    <v-col cols="12" class="pa-0">
       <!-- <v-data-iterator
        :items="data"
        item-key="name"
        :items-per-page="10"
        :search="search"
        :loading="loadingData"
        v-if="$vuetify.breakpoint.xsOnly"
      >
        <template v-slot:default="{ items }">
          <template v-for="(client,i) in items">
            <mobile-clients :cliente="client" :key="'mobilec-'+i">
              <template #action>
                <v-btn 
                  depressed
                  color="secondary"
                  outlined
                  class="mx-2 pa-2"
                  min-width="23px"
                  height="31px"
                  :to="{name: 'Cliente', params: { cod: client.co_cli.trim() }}"
                >
                <v-icon size="20">mdi-text-box-check-outline</v-icon>
              
                </v-btn>
              </template>
            </mobile-clients>
          </template>
        </template>
        <template #loading>
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-two-line, list-item, list-item-two-line"
          ></v-skeleton-loader>
        </template>
      </v-data-iterator> -->
      <v-data-table
        :headers="dataheaders"
        :items="data"
        :search="search"
        :loading="loadingData"
        sort-by="co_cli_po"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
      >
        <template v-slot:item.nombre_comercial="{ item }">
          <div class="d-flex align-start py-3">
            <v-icon 
              color="primary" 
              left
              size="20"
            >
              mdi-account-tie
            </v-icon>
            <div class="d-flex flex-column justify-center">
              <span class="primary--text font-weight-bold" v-text="item.nombre_comercial" />
              <div class="subtitle blue-grey--text font-italic">RIF: {{item.rif}}</div>
            </div>
          </div>
        </template>
        <template v-slot:item.persona_contacto="{ item }">
          <div class="d-flex flex-column justify-center">
            <span class="primary--text font-weight-bold" v-text="item.persona_contacto" />
            <div class="subtitle blue-grey--text font-italic">Teléfono: {{item.telefono}}</div>
          </div>
        </template>
        <template v-slot:item.ven_des="{ item }">
          <div class="d-flex align-center">
            <span class="black--text font-weight-regular">
              <strong>({{item.co_ven}})</strong>
               {{ item.ven_des }}
            </span>
          </div>
        </template>
        <template v-slot:item.info="{ item }">
          <div class="d-flex justify-end">
            <v-chip
              label
              color="blue-grey lighten-5"
              class="pa-1"
            >
              <v-icon 
                size="20" 
                :color="item.lat !== null && item.lng !== null ? 'icono' : 'blue-grey lighten-3'" 
                class="mx-2"
                v-text="item.lat !== null && item.lng !== null ? 'mdi-map-marker-check' : 'mdi-map-marker-off' "
              />
              <slot name="actionInfo" :client="item"></slot>
            </v-chip>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-right d-flex justify-end">
            <div v-if="$vuetify.breakpoint.xsOnly" class="d-flex justify-end">
              <v-chip
                label
                color="blue-grey lighten-5"
                class="pa-1"
              >
                <v-icon 
                  size="20" 
                  :color="item.lat !== null && item.lng !== null ? 'icono' : 'blue-grey lighten-3'" 
                  class="mx-2"
                  v-text="item.lat !== null && item.lng !== null ? 'mdi-map-marker-check' : 'mdi-map-marker-off' "
                />
              </v-chip>
            </div>
            <v-menu
            bottom
            left
            min-width="200"
            offset-y
            origin="top right"
            transition="scale-transition"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn  
                  v-bind="attrs"
                  v-on="on" 
                  plain 
                  small 
                  color="blue-grey" 
                  class="px-0"
                >
                  <v-icon size="20"  color="icono">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list
                class="py-0"
                flat
              >
                <v-list-item-group
                  color="primary"
                >
                  <template v-if="$hasPermission('clientes.consulta')">
                    <v-list-item
                      class="py-2"
                      :to="{name: 'ClientePotencial', params: { cod: item.co_cli_po }}"
                    >
                      <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                        <v-icon color="light-blue darken-3" size="22">
                          mdi-text-box-check-outline
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        <span>Ver detalles</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                  </template>
                  <v-list-item
                    v-if="$hasPermission('clientes.potencial.editar')"
                    class="py-2"
                    @click="gestionItem(item)"
                  >
                    <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                      <v-icon size="22" color="light-blue darken-3">mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>      
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
          <!-- <div class="actions--style text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  depressed
                  class="mx-2 pa-2"
                  min-width="23px"
                  height="31px"
                  v-bind="attrs"
                  v-on="on"
                  :to="{name: 'Cliente', params: { cod: item.co_cli_po }}"
                >
                <v-icon size="20">mdi-text-box-check-outline</v-icon>
                </v-btn>
              </template>
              <span>Ver Detalles</span>
            </v-tooltip>          
          </div> -->
        </template>
        <template #loading>
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-skeleton-loader
                v-bind="attrs"
                type="table-tbody"
                :width="$vuetify.breakpoint.xsOnly ? $vuetify.breakpoint.width - 70 : '100%'"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</div>
</template>
<script>
export default {
  name: 'DataAccounts',
  components:{
    // MobileClients: () => import(
    //   /* webpackChunkName: "modal-account" */
    //   './MobileClients.vue'
    // ),
  },
  props:{
    headers: {
      type: Array,
      default: () => ([]),
    },
    data: {
      type: Array,
      default: () => ([]),
    },
    search: String,
    loadingData: Boolean,
  },
  data: () => ({
    attrs: {
      boilerplate: false,
      loading: true,
    },
    edit: false,
    client: {},
    
  }),
  methods: {
    gestionItem (item) {
      this.$emit('edit', item)
    },
  },
  computed: {
    dataheaders() {
      return this.headers.filter(item => item.active)
    },
  }
}
</script>
